/************* header ****************/
header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 121;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
}

header .top {
    background: rgb(var(--color-1));
    color: #fff;
    height: 80px;
    padding: 0;
}

header .logo {
    display: block;
}

header .logo img {
    width: 250px;
}

/* Search form */
.header_search {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.header_search input {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 300px;
}

.header_search button {
    width: 2.5em;
    padding: 0;
    background-color: #E2E8F3;
    border: 1px solid #C4C4C4;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: rgb(var(--color-1));
    font-size: 1.5em;
}

header .btn-icon,
header .btn-icon2 {
    position: relative;
    display: block;
    font-size: 2em;
    color: #fff;
    transition: var(--transition);
}

header .btn-icon:hover,
header .btn-icon:focus,
header .btn-icon:active {
    color: rgb(var(--color-2));
}

.notificationAll {
    position: absolute;
    width: 21px;
    height: 21px;
    text-align: center;
    border-radius: 50%;
    transition: var(--transition);
    background-color: rgb(var(--color-2));
    color: #fff;
    font-size: 12px;
    line-height: 22px;

}

.notification {
    bottom: 70%;
}

header .btn-icon span {
    bottom: 50%;
    left: 65%;
}

header .btn-icon:hover span,
header .btn-icon:focus span,
header .btn-icon:active span {
    background-color: #fff;
    color: rgb(var(--color-2));
}

/* MENU */
header .bottom {
    height: 50px;
    padding: 0px;
    background-color: #E2E8F3;
}

nav#main-menu {
    width: 100%;
    height: 100%;
    padding: 0px !important;
}

nav#main-menu ul {
    list-style: none;
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

nav#main-menu .nav-item {
    border-radius: 2em;
    padding: .3em 0;
    border: 1px solid transparent;
}

nav#main-menu.nav-link {
    color: #252525;
    font-weight: 400;
    padding: 0 !important;
    margin: 0;
    border: 1px solid transparent;
}

.nav-link.active,
.nav-link:hover,
.nav-link:focus,
.nav-link:active,
.nav-link.active {
    color: rgb(var(--color-1));
    font-weight: 500;
}

@media (max-width: 1199px) {
    header .logo img {
        width: 200px;
    }

    .header_search input {
        min-width: 250px;
    }
}

@media (max-width: 991px) {
    header .top {
        height: 65px;
    }

    .header_search {
        flex: 1;
        margin-left: 1em;
    }

    .nav-link {
        font-size: .85em;
    }
}

@media (max-width: 767px) {
    .nav-link {
        font-size: 1.2em;
    }

    header .top {
        height: 50px;
    }

    header .bottom {
        height: 40px;
    }
}

@media (max-width: 575px) {
    section.top {
        height: 50px;
    }

    header {
        height: 60px;
    }

    header .logo {
        width: 160px;
    }

    header .logo img {
        width: 100%;
    }

    header .btn-icon {
        font-size: 1.5em;
    }

    header .btn-icon span {
        width: 16px;
        height: 16px;
        line-height: 16px;
    }

    .header_search input {
        min-width: unset;
    }

    .header_search button {
        width: 2em;
    }
}


main {
    min-height: calc(100vh - 130px - 240px);
}

@media (max-width: 991px) {
    main {
        min-height: calc(100vh - 115px - 270px);
    }
}

@media (max-width: 767px) {
    main {
        min-height: calc(100vh - 90px - 40px);
    }
}


/*********
block 1
*********/
.swiper-1 h2 {
    font-size: 2.5em;
    color: #fff;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
}

.swiper-1 h2::before,
.swiper-1 h2::after {
    content: unset;
}

.swiper-1 .swiper-slide {
    position: relative;
    height: 440px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.swiper-1 .swiper-slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.swiper-1 .swiper-slide .container {
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}

.swiper-1 .swiper-pagination {
    bottom: 1em !important;
}

.swiper-1 .swiper-pagination-bullet-active {
    background-color: #fff !important;
}

@media (max-width: 991px) {
    .swiper-1 .swiper-slide {
        height: 380px;
    }
}

@media (max-width: 575px) {
    .swiper-1 h2 {
        font-size: 1.2em;
    }

    .swiper-1 .swiper-slide {
        height: 30vw;
        padding: 3em 0px;
    }
}


/*********
block 2
Информация в цифрах
*********/
.info_in_nums {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    box-sizing: border-box;
}

.info_in_nums>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info_in_nums>div:nth-child(2) {
    display: flex;
    align-items: flex-end;
}

.info_in_nums img {
    width: 6em;
    margin-left: .5em;
}

@media (max-width: 1399px) {
    .info_in_nums img {
        width: 5em;
    }
}

@media (max-width: 1199px) {
    .info_in_nums img {
        width: 4em;
    }
}

.dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;

}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
}

.dropdown-content a {
    background-color: rgb(var(--color-1) / 15%);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: white;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/*********
block 3
блок с видео
**********/
#imgForVideo img {
    width: 100%;
    height: 100%;
}

#imgForVideo {
    width: 100%;
    height: 220px;
    object-fit: contain;
}

#block_3 video {
    width: 100%;
    height: 220px;
    object-fit: contain;
    padding: 2px;
}

@media (max-width: 1199px) {

    #block_3 video,
    #imgForVideo {
        height: 180px;
    }
}

@media (max-width: 991px) {

    #block_3 video,
    #imgForVideo {
        height: 140px;
    }
}

@media (max-width: 767px) {

    #block_3 video,
    #imgForVideo {
        height: 300px;
    }
}

@media (max-width: 575px) {

    #block_3 video,
    #imgForVideo {
        height: 55vw;
    }
}


/*********
block 3
Превью предложений
**********/
.preview-small {
    position: relative;
}

.preview-small img {
    width: 100%;
    height: 225px;
    object-fit: cover;
    display: block;
    cursor: pointer;
}

.preview-small .text {
    position: relative;
    height: 4em;
    color: #fff;
}

.layer1 {
    position: absolute;
    background-color: rgba(var(--color-1)/100%);
    bottom: 0%;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 0 1em;
    transition: .3s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.layer1 h6 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2em;
    height: 2.4em;
}

.preview-small:hover .layer1 {
    bottom: 100%;
    background-color: rgba(var(--color-1)/80%);
}

.layer2 {
    position: relative;
    background-color: rgba(var(--color-1)/100%);
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
}

.layer2 .btn_main {
    font-size: 2em;
}

.preview-slider img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    display: block;
}

@media (max-width: 1399px) {
    .preview-small>img {
        height: 205px;
    }
}

@media (max-width: 1199px) {
    .preview-small {
        font-size: 13px;
    }

    .preview-small>img {
        height: 180px;
    }

    .preview-slider img {
        height: 235px;
    }
}

@media (max-width: 991px) {
    .preview_grid_mini {
        grid-gap: 15px 15px;
    }

    .preview-small>img {
        height: 175px;
    }

    .preview-slider img {
        height: 175px;
    }

    .preview-small .text {
        position: static;
        height: auto;
    }

    .layer1 {
        position: relative;
        padding: .4em 1em .2em;
    }

    .preview-small:hover .layer1 {
        bottom: unset;
        background-color: rgba(var(--color-1)/100%);
    }

    .layer2 {
        position: static;
        padding: .2em 1em .4em;
    }

    .layer2 .btn-fav {
        position: absolute;
        top: 0px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(var(--color-2));
    }
}

@media (max-width: 767px) {
    .preview-small>img {
        height: 180px;
    }

    .preview-slider img {
        height: 130px;
    }
}

@media (max-width: 576px) {
    .preview-small {
        font-size: 11px;
    }

    .preview-small>img {
        height: 40vw;
    }

    .preview-slider img {
        height: 24vw;
    }
}


/****** Фильтр ******/
.filter_line_1 {
    background-color: #E2E8F3;
    padding: 2em;
    border: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapse-filter {
    border: 1px solid #DDDDDD;
    padding: 1em;
    background-color: #EFEFEF;
    margin-top: -1px;
}

.filter__btn {
    width: 120px;
    text-align: left;
    text-decoration: underline;
}

@media (max-width: 1199px) {

    .filter_line_1,
    .collapseFilter {
        padding: 1.5em;
    }
}

@media (max-width: 991px) {

    .filter_line_1,
    .collapseFilter {
        padding: 1em;
    }
}

@media (max-width: 575px) {
    .filter_line_1 {
        flex-wrap: wrap;
    }

    .filter_line_1 div:nth-child(2) {
        width: 100%;
        text-align: center;
        margin-bottom: .5em;
    }
}

/***** сортировка *****/
.sort {
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    padding: 1em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav[aria-label="page-pagination"] {
    width: fit-content;
    width: -moz-fit-content;
    padding: 0 !important;
}

nav>ul.pagination {
    list-style: none;
    padding: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

nav>ul.pagination .page-item {
    margin-right: .5em;
}

/* Active pagintion element styles */
nav>ul.pagination .page-item.selected {
    background-color: #777777;
}

nav>ul.pagination .page-item.selected .page-link {
    color: #FFF;
}

/* ------------------------------- */

nav>ul.pagination .page-item:last-child {
    margin-right: 0;
}

nav>ul.pagination .page-link {
    color: #606060;
    border: 1px solid #606060;
    background-color: transparent;
    border-radius: 1px;
    padding: 0;
    width: 2.25em;
    height: 2.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9em;
}

nav>ul.pagination .page-link:hover,
nav>ul.pagination .page-link:active,
nav>ul.pagination .page-item.active .page-link {
    color: rgb(var(--color-2));
    border: 1px solid rgb(var(--color-2));
    background-color: transparent;
}

nav>ul.pagination .page-item:first-child .page-link,
nav>ul.pagination .page-item:last-child .page-link {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}

.sort select {
    width: fit-content;
    width: -moz-fit-content;
}

@media (max-width: 767px) {
    .sort {
        flex-wrap: wrap;
    }

    .sort nav[aria-label="page-pagination"] {
        width: 100%;
        margin-bottom: 1em;
    }

    .sort .pagination {
        justify-content: center;
    }
}

@media (max-width: 575px) {
    .sort {
        justify-content: center;
    }
}


/***********
#block 4
Новости и статьи
************/
.news-mini h6 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.25em;
    height: 2.5em;
    font-family: var(--pt);
    font-weight: 400;
}

.news-preview {
    position: relative;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    transition: var(--transition);
}

.news-preview:hover {
    box-shadow: 0px 10px 30px rgba(60, 100, 177, 0.2);
}

.news-preview img {
    position: relative;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.time {
    position: relative;
    background-color: #F0F0F0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    width: -moz-fit-content;
    font-size: 1.2em;
    padding: .25em 1em;
    margin: -1.5em 1em 0 auto;
}

.news-preview .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 700;
    line-height: 1.25em;
    height: 2.5em;
    margin-bottom: .75em;
}

.news-preview .text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.25em;
    height: 3.75em;
    margin-bottom: 1em;
    font-family: var(--pt);
}

.news-preview .more {
    display: block;
    color: rgb(var(--color-1));
    font-family: var(--pt);
    font-size: .9em;
    text-decoration: underline;
    margin: 0 0 0 auto;
    width: fit-content;
    cursor: pointer;
    transition: var(--transition);
}

.news-preview .more:hover,
.news-preview .more:focus,
.news-preview .more:active {
    color: rgb(var(--color-2));
}

@media (max-width: 1399px) {
    .news-preview img {
        height: 160px;
    }
}

@media (max-width: 1199px) {
    .news-preview img {
        height: 145px;
    }

    .time {
        font-size: 1.1em;
    }
}

@media (max-width: 575px) {
    .news-preview img {
        height: 60vw;
    }
}


/***********
#block 5 Партнеры
************/
#block_5 .swiper-slide img {
    object-fit: cover;
    cursor:pointer;
    width:100%;
    aspect-ratio: 5/2;
    object-position: center;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    transition: var(--transition);
}

#block_5 .swiper-slide img:hover {
    box-shadow: 10px 10px 30px rgba(60, 100, 177, 0.15);
}

/*** news page ***/
article.full,
article.full .text {
    margin-bottom: 2em;
}

article.full .text p {
    margin-bottom: 1.5em;
    font-family: var(--pt);
    font-size: 1.1em;
}

article.full .return {
    font-family: var(--roboto);
    color: rgb(var(--color-1));
    border-bottom: 2px solid;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 0.1em;
    width: fit-content;
}

.new-page-img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1.5em;
}

@media (max-width: 1399px) {
    .new-page-img {
        height: 400px;
    }
}

@media (max-width: 1199px) {
    .new-page-img {
        height: 350px;
    }
}

@media (max-width: 991px) {
    .new-page-img {
        height: 280px;
    }

    article.full .text p {
        font-size: 1em;
    }
}

@media (max-width: 767px) {
    .new-page-img {
        height: 230px;
    }
}

@media (max-width: 575px) {
    .new-page-img {
        height: 40vw;
    }

    article.full .text p {
        font-size: .9em;
        margin-bottom: 1em;
    }
}


/***********
#block 6
Страница одного предложения
************/
#offer-page h1 {
    font-family: var(--roboto);
    font-size: 3em;
    font-weight: 900;
    text-transform: capitalize;
    text-align: left;
    color: var(--color-3);
    margin-bottom: 0.25em;
}

#offer-page h2 {
    font-family: var(--roboto);
    font-size: 1.5em;
    font-weight: 900;
    text-transform: lowercase;
    text-align: left;
}

#offer-page h2::before,
#offer-page h2::after {
    content: unset;
}

.short-info {
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 575px) {
    #offer-page h1 {
        font-size: 1.7em;
        margin-bottom: 0px;
    }

    #offer-page h2 {
        font-size: 1em;
    }

    .short-info {
        font-size: .8em;
    }
}

#offer-page .left_menu {
    position: sticky;
    top: 130px;
    left: 0;
    width: 100%;
}

.anchor-menu ul {
    list-style: none;
    padding-left: 0;
}

.anchor-menu a {
    font-family: var(--pt);
    padding: 1em;
    font-size: 1.1em;
    display: block;
    border-left: 5px solid transparent;
}

.anchor-menu a:hover,
.anchor-menu a:active,
.anchor-menu a.active {
    background-color: rgb(var(--color-1) / 15%);
    border-left: 5px solid #3C64B1;
    cursor: pointer;
}

.anchor-menu a.active {
    border-left: 5px solid rgb(var(--color-1));
}

section.anchor_block {
    padding-top: 1em;
    padding-bottom: 0;
}

@media (max-width: 1399px) {
    .promo {
        font-size: 14px;
    }
}

@media (max-width: 1199px) {
    .anchor-menu a {
        font-size: 1em;
    }

    .promo {
        font-size: 13px;
    }
}

@media (max-width: 575px) {
    .promo {
        font-size: 11px;
    }
}

.main-img {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 4/3
}

.blue-box {
    background: linear-gradient(156.98deg, #F3F7FF -12.48%, #D2E2FF 128.58%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 2em;
}

.user img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

@media (max-width: 1399px) {

    .blue-box {
        padding: 1em;
    }
}

@media (max-width: 1399px) {}

@media (max-width: 767px) {}

@media (max-width: 575px) {
    .main-img {
        border-radius: 10px;
    }

    .blue-box {
        font-size: 10px;
        border-radius: 10px;
    }

    .user img {
        width: 30px;
        height: 30px;
    }
}

.offer-page-main p,
.offer-page-main ol,
.offer-page-main ul {
    line-height: 1.4em;
    margin-bottom: 2em;
    font-size: 1.1em;
    font-family: var(--pt);
    color: #000;
}

.offer-page-main ul {
    list-style: none;
    padding-left: 0;
}

.offer-page-main ul>li {
    margin-bottom: 1em;
}

.offer-page-main ul>li::before {
    content: "⦁";
    color: #2E5193;
    padding-right: 1em;
    font-size: 1.3em;
    line-height: 1em;
}

.offer-page-main ol {
    list-style: none;
    padding-left: 0;
}

.offer-page-main ol>li {
    counter-increment: inst;
    margin-bottom: 1em;
}

.offer-page-main ol>li::before {
    content: counter(inst) ".";
    padding-right: .5em;
}

@media (max-width: 575px) {

    .offer-page-main p,
    .offer-page-main ol,
    .offer-page-main ul {
        font-size: .9em;
    }
}

.photogallery {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

@media (max-width: 1199px) {
    .photogallery {
        height: 130px;
    }
}

@media (max-width: 991px) {
    .photogallery {
        height: 115px;
    }
}

@media (max-width: 575px) {
    .photogallery {
        height: 33vw;
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.tags span {
    color: #606060;
    background-color: rgb(var(--color-1) / 15%);
    padding: .75em 1em;
    border-radius: 3px;
    margin-right: .75em;
    margin-bottom: .75em;
    font-size: .9em;
}

@media (max-width: 575px) {
    .tags span {
        font-size: .7em;
    }
}


/***************
personal account
****************/
.acc-box {
    border: 1px solid #E7E8EC;
    border-radius: 5px;
    padding: 1em;
}

.acc-box>.chat-preview:nth-child(odd) {
    background-color: rgba(231, 232, 236, 0.2);
}

.acc-menu {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    overflow: hidden;
    height: fit-content;
    height: -moz-fit-content;
    padding: 0 !important;
}

.acc-menu ul li:not(:first-child) {
    border-top: 1px solid #DDDDDD;
}

.acc-menu ul li a,
.acc-menu ul li button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1em .5em 1em 1.5em !important;
    position: relative;
    font-weight: 400;
    cursor: pointer;
    transition: var(--transition);
    border-left: 7px solid transparent;
}

.acc-menu ul li a:hover,
.acc-menu ul li a:focus {
    color: rgb(var(--color-1));
}

.acc-menu ul li button:hover,
.acc-menu ul li button:focus {
    color: rgb(var(--color-2));
}

.acc-menu ul li a.active,
.acc-menu ul li button.active {
    color: rgb(var(--color-1));
    border-left: 7px solid rgb(var(--color-1));
}

.acc-menu ul li a.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #707070;
}

@media (max-width: 1199px) {

    .acc-menu ul li a,
    .acc-menu ul li button {
        padding: 0.8em 0.5em 0.8em 0.5em !important;
    }
}

@media (max-width: 991px) {

    .acc-menu ul li a,
    .acc-menu ul li button {
        padding: 1em 0.5em 1em 1em !important;
        font-size: 1.2em;
    }
}

@media (max-width: 767px) {
    nav.acc_menu {
        display: none;
    }
}


.user-photo {
    border: 1px solid #E7E8EC;
    border-radius: 5px;
    width: 100%;
    height: 280px;
    object-fit: cover;
}

.acc-friends {
    color: rgb(var(--color-1));
    text-align: center;
    font-size: .8em;
}

.acc-friends img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
}

.acc-table {
    font-size: .9em;
}

.ads-img {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 50%;
}

.acc-video2 {
    margin-bottom: 5px;
    overflow: hidden;
    max-height: 400px;
}

.acc-video2 video,
.acc-video2 iframe {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    background: black;
}

@media (min-width: 1399px) {
    .acc-video2 {
        min-width: 80%;
        height: 30vw;
    }
}

@media (max-width: 1399px) {
    .user-photo {
        height: 240px;
    }

    .acc-video2 {
        min-width: 80%;
        height: 30vw;
    }

}

@media (max-width: 1199px) {
    .user-photo {
        height: 200px;
    }

    .acc-video2 {
        min-width: 80%;
        height: 30vw;
    }


}

@media (max-width: 767px) {
    .user-photo {
        height: 450px;
    }

    .acc-video2 {
        max-width: 80%;
        height: 45vw;
    }

}

@media (max-width: 575px) {
    .user-photo {
        height: calc(100vw - 71px);
    }

    .acc-table {
        font-size: .75em;
    }

    .acc-table td {
        padding: 0.5rem 0rem;
    }

    .ads {
        font-size: 10px;
        padding: 0.5em 0.25em;
    }

    .ads-img {
        width: 50px;
        height: 50px;
    }

    .acc-video2 {
        max-width: 80%;
        height: 55vw;
    }
}






.acc-video {
    width: 100%;
    height: 200px;
    margin-bottom: 5px;
    overflow: hidden;
}

.acc-video video,
.acc-video iframe {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    background: black;
}

.ads-img {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 50%;
}

@media (min-width: 1399px) {
    .acc-video {
        height: 175px;
    }

}

@media (max-width: 1399px) {
    .user-photo {
        height: 240px;
    }
}

@media (max-width: 1199px) {
    .user-photo {
        height: 200px;
    }

    .acc-video {
        height: 150px;
    }

}

@media (max-width: 767px) {
    .user-photo {
        height: 450px;
    }
}

@media (max-width: 575px) {
    .user-photo {
        height: calc(100vw - 71px);
    }

    .acc-table {
        font-size: .75em;
    }

    .acc-table td {
        padding: 0.5rem 0rem;
    }

    .ads {
        font-size: 10px;
        padding: 0.5em 0.25em;
    }

    .ads-img {
        width: 50px;
        height: 50px;
    }

    .acc-video {
        height: 55vw;
    }
}

.acc-video-block>a {
    color: #0c63e4;
}



















/* незаполненные блоки */
.empty-space,
.empty-space-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 120px;
}

.empty-space-2 {
    min-height: 450px;
}


/**
how to upload
**/
.instruction {
    position: relative;
}

.instruction img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    margin-bottom: 1em;
}

.instruction iframe {
    display: block;
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.notch {
    position: absolute;
    top: 130px;
    right: 1em;
    width: fit-content;
    background: #F0F0F0;
    border-radius: 4px;
    text-align: center;
    z-index: 10;
    width: 5.6em;
    height: 2.8em;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notch>div {
    line-height: 1.1em;
}

.play {
    width: 2em;
    height: 2em;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play svg path {
    fill: rgb(var(--color-2));
}

.instruction .text {
    height: 7.2em;
    overflow: hidden;
}

.instruction .text>div {
    line-height: 1.2em;
}

.instruction .text .title {
    font-weight: 700;
    margin-bottom: 1.2em;
}

@media (max-width: 1199px) {

    .instruction img,
    .instruction iframe {
        height: 140px;
    }

    .notch {
        top: 115px;
        font-size: .9em;
    }

    .instruction .text>div {
        line-height: 1.1em;
    }

    .instruction .text .title {
        margin-bottom: 1.1em;
    }

    .instruction .text {
        height: 6.6em;
    }
}

@media (max-width: 575px) {

    .instruction img,
    .instruction iframe {
        height: 55vw;
    }

    .notch {
        top: 55vw;
        margin-top: -2em;
    }
}


/* поиск 2 */
.form_search {
    display: flex;
    align-items: center;
    width: 300px;
}

.form_search input {
    background-color: #EBEDF0;
    border-radius: 6px;
    border: none;
    padding: .5em .5em .5em 2em;
    /* background: url(../images/icons/search.svg) no-repeat #EBEDF0; */
    background-position: .5em center;
}

.form_search button {
    display: none;
}

@media (max-width: 576px) {
    .form_search {
        width: 100%;
    }
}

.acc-box ul.pagination .page-link {
    border: none;
    font-size: 1em;
    width: 3.5em;
    height: 3em;
}

.acc-box ul.pagination .page-link:hover,
.acc-box ul.pagination .page-link:active,
.acc-box ul.pagination .page-link.active {
    color: #000;
    border: none;
    background-color: #E7E8EC;
}

@media (max-width: 575px) {
    .acc-box ul.pagination .page-item {
        margin-right: 0;
    }

    .acc-box ul.pagination .page-link {
        width: 2.5em;
        height: 2.5em;
        font-size: .9em;
    }
}

/* tabs */
.tab-btns {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: -1px;
}

.tab-btns button {
    flex-grow: 1;
    font-size: .9em;
    background: #F8F8F8;
    border: 1px solid #E7E8EC;
    border-radius: 2px 2px 0px 0px;
    padding: 0.75em 0;
}

.tab-btns button:not(:first-child) {
    margin-left: 3px;
}

.tab-btns button.active {
    background: #fff;
    border-bottom: 1px solid transparent;
}

@media (max-width: 1399px) {
    .tab-btns button {
        font-size: .8em;
    }
}

@media (max-width: 1199px) {
    .tab-btns button {
        font-size: .75em;
    }
}

@media (max-width: 767px) {
    .tab-btns {
        flex-wrap: wrap;
    }

    .tab-btns button {
        font-size: .8em;
    }

    .tab-btns button:not(:first-child) {
        margin-left: 0px;
    }
}

.ad-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ad-preview:not(:first-child) {
    margin-top: 3em;
}

.ad-preview .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: unset;
}

.ad-preview .img {
    width: 140px;
    height: 100px;
    padding: 0;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
}

.ad-preview .img img {
    width: 140px;
    height: 100px;
    object-fit: cover;
}

.ad-preview .btns {
    width: 330px;
    font-size: .8em;
}

@media (max-width: 1199px) {
    .ad-preview .img {
        width: 100px;
        height: 70px;
        margin-right: 0.5em;
    }

    .ad-preview .btns {
        width: 265px;
        font-size: .75em;
    }
}

@media (max-width: 767px) {
    .ad-preview {
        flex-direction: column;
        align-items: flex-start;
    }

    .ad-preview .btns {
        width: 100%;
    }
}

@media (max-width: 575px) {

    .ad-preview>div,
    .ad-preview div.flex-1 {
        width: 100%;
    }

    .ad-preview .img {
        width: 100%;
        height: 60vw;
        margin-right: 0;
        margin-bottom: 1em;
    }

    .ad-preview .img a {
        width: 100%;
        height: 100%;
    }

    .ad-preview .img a img {
        width: 100%;
        height: 100%;
    }
}

.ad-price {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.ad-price img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 220px;
    object-fit: cover;
}

.ad-price .text {
    position: absolute;
    z-index: 2;
    left: 0px;
    bottom: -70px;
    width: 100%;
    height: 140px;
    background-color: #777777;
    color: #fff;
    padding: .5em;
    transition: var(--transition);
}

.ad-price .text:hover {
    bottom: 0px;
}

.ad-price .blocked {
    position: absolute;
    z-index: 3;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 2em;
}

.ad-price.picked {
    outline: 2px solid rgb(var(--color-1));
}

.ad-price.picked .text {
    background-color: rgb(var(--color-1));
}

@media (max-width: 1399px) {
    .ad-price img {
        height: 250px;
    }
}

@media (max-width: 1199px) {
    .ad-price img {
        height: 220px;
    }
}

@media (max-width: 575px) {
    .ad-price {
        font-size: .7em;
    }

    .ad-price img {
        height: 42vw;
    }

    .ad-price .text {
        bottom: 0px;
        height: fit-content;
    }
}

.requests-count {
    background-color: #FF3347;
    color: #fff;
    font-size: .9em;
    height: 1.2em;
    width: 1.2em;
    display: inline-block;
    line-height: 1.2em;
    text-align: center;
    border-radius: 50%;
}

.friend-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
}

.friend-row img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: .5em;
}

.friend-row-text {
    flex: 1;
}

@media (max-width: 767px) {
    .friend-row {
        font-size: .9em;
    }
}

@media (max-width: 576px) {
    .friend-row {
        font-size: .8em;
    }

    .friend-row img {
        width: 30px;
        height: 30px;
    }
}

form.promo {
    max-width: 200px;
}

.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-item:not(:first-child) {
    margin-top: 1em;
}

.cart-item img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 2em;
}

.cart-item select {
    flex: 1;
}

.cart-item .price {
    text-align: right;
    width: 250px;
    color: rgb(var(--color-1));
    margin-left: 1em;
}

@media (max-width: 767px) {
    .cart-item .price {
        width: unset;
        margin-left: 0px;
        margin-top: 1em;
    }

    .cart-item img {
        width: 80px;
        height: 80px;
        margin-right: 1em;
    }
}

@media (max-width: 575px) {
    .cart-item {
        align-items: flex-start;
    }

    .cart-item img {
        width: 50px;
        height: 50px;
        margin-right: .5em;
    }

    .cart-item .price {
        margin-top: .5em;
    }
}

.photogallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-gap: 1em;
}

.photogallery a {
    grid-column: span 1;
    grid-row: span 1;
}

.photogallery a img {
    width: 100%;
    height: 145px;
}

@media (max-width: 1199px) {
    .photogallery {
        grid-template-columns: repeat(4, 1fr);
    }

    .photogallery a img {
        height: 150px;
    }
}

@media (max-width: 991px) {
    .photogallery {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: .5em;
    }

    .photogallery a img {
        height: 135px;
    }
}

@media (max-width: 767px) {
    .photogallery a img {
        height: 155px;
    }
}

@media (max-width: 575px) {
    .photogallery a img {
        height: 25vw;
    }
}


/**************
CHAT
**************/
.chat-preview {
    display: flex;
    cursor:pointer;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    font-size: .9em;
}

.chat-preview .user {
    color: rgb(var(--color-1)/100%);
    text-align: center;
    width: 50px;
}

.chat-preview .user img,
.chat-window .user img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.chat-preview .info,
.chat-preview .text {
    flex: 1;
    margin-left: 1em;
}

.chat-preview .text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
    max-height: 3.6em;
    overflow: hidden;
}

.chat-preview .btns {
    display: flex;
    align-items: center;
    margin-left: 1em;
}

.chat-preview .unread-count-container {
    width: 30px;
    height: 30px;
}

.chat-preview .unread-count-container .unread-count {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #324e90;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-message {
    background-color: rgb(231 232 236 / 80%);
}

.chat-window {
    position: relative;
    overflow: hidden;
}

.chat-window .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E7E8EC;
}

.chat-window .middle {
    max-height: 700px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    scrollbar-color: rgb(155 155 155 / 46%) transparent;
    scrollbar-width: thin;
}

.chat-window .middle::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.chat-window .middle::-webkit-scrollbar-thumb {
    background-color: rgb(155 155 155 / 46%);
    border-radius: 3px;
    transition: .2s;
}

.chat-window .middle::-webkit-scrollbar-thumb:hover {
    background-color: gray;
}

.chat-window .middle::-webkit-scrollbar-button:vertical:start:decrement,
.chat-window .middle::-webkit-scrollbar-button:vertical:end:increment,
.chat-window .middle::-webkit-scrollbar-button:horizontal:start:decrement,
.chat-window .middle::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
}


.message,
.reply {
    border-radius: 10px;
    max-width: 350px;
    padding: 1em;
    margin-bottom: 1em;
}

.message .user img,
.reply .user img {
    width: 35px;
    height: 35px;
}

.message {
    background-color: #F5F5F5;
    margin-left: auto;
}

.reply {
    border: 1px solid #E7E7E7;
}

.message p,
.reply p {
    margin-bottom: 1em;
}

.chat-window .bottom {
    background-color: #F8F8F8;
}

.chat-window .bottom textarea {
    border-color: transparent;
}

@media (max-width: 991px) {
    .chat-window .middle {
        max-height: 600px;
    }
}

@media (max-width: 767px) {
    .chat-preview .info {
        display: none;
    }

    .chat-preview .text {
        margin-left: 4em;
    }

    .message,
    .reply {
        max-width: 300px;
    }

    .message p,
    .reply p {
        font-size: .9em;
        margin-bottom: 0.5em;
    }
}

@media (max-width: 575px) {
    .chat-preview {
        padding: .75em;
    }

    .chat-preview .text {
        margin-left: 4em;
        font-size: .85em;
    }

    .chat-window .middle {
        max-height: 280px;
    }

    .chat-preview .user img,
    .chat-window .user img {
        width: 35px;
        height: 35px;
    }

    .message,
    .reply {
        max-width: 200px;
        padding: 0.5em;
        font-size: .8em;
        border-radius: 3px;
    }
}


.hint-img {
    border: 1px solid rgb(var(--color-1));
    display: none;
    position: absolute;
    top: 100%;
    z-index: 100;
}

.hint-big {
    left: calc(-300px + 50%);
    width: 600px;
}

.hint-sm {
    left: calc(-170px + 50%);
    width: 340px;
}

.video-50 {
    width: 100%;
    height: 320px;
}

@media (max-width: 1199px) {
    .video-50 {
        height: 270px;
    }
}

@media (max-width: 991px) {
    .hint-big {
        left: calc(-250px + 50%);
        width: 500px;
    }

    .video-50 {
        height: 200px;
    }
}

@media (max-width: 767px) {
    .video-50 {
        height: 150px;
    }
}

@media (max-width: 575px) {
    .add_banner .radio {
        flex-wrap: wrap;
    }

    .add_banner .radio .price {
        width: 100%;
        display: block;
        text-align: left;
        margin-top: .5em;
        padding-left: 30px;
    }

    .hint-big,
    .hint-sm {
        left: unset;
        right: 0;
        width: calc(100vw - 30px);
    }

    .video-50 {
        height: 60vw;
    }
}


.img-preview {
    position: relative;
    border-radius: 3px;
    border: 1px solid #C4C4C4;
    overflow: hidden;
}

.img-preview img {
    width: 100%;
    height: 80px;
    object-fit: cover;
}

.img-preview button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    opacity: .7;
}

.img-preview button svg {
    width: 16px;
    height: 16px;
}

.img-preview button svg path {
    stroke: #000;
    stroke-width: 7px;
}

.img-preview button:hover,
.img-preview button:active {
    opacity: 1;
}

@media (max-width: 1199px) {
    .img-preview img {
        height: 95px;
    }
}

@media (max-width: 991px) {
    .img-preview img {
        height: 70px;
    }
}

@media (max-width: 575px) {
    .img-preview img {
        height: 27vw;
    }
}


.placing {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.placing-1 {
    flex: 1;
}

.placing-2 {
    width: 200px;
    text-align: center;
}

.placing-3 {
    width: 170px;
    text-align: center;
    margin-left: 2em;
}

/**************
NEW
***************/
.advertising-block {
    color: #fff;
    background-color: #acb5c7;
    position: relative;
    padding: .5em;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    /* border: 2px solid transparent; */
}

.advertising-block input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 2;
    border: none;
    background-color: transparent;
}

.advertising-block input:checked {
    border: 2px solid rgb(var(--color-1));
}

.advertising-block .title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.advertising-block .stat {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2em;
    max-height: 2.4em;
    overflow: hidden;
}

/* .advertising-block.active {
    border: 2px solid rgb(var(--color-1));
} */
.advertising-block-disabled input {
    pointer-events: none;
}

.advertising-block-disabled::after {
    content: "Заблокировано";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
}

.advertising-block-taken {
    background-color: rgb(var(--color-2));
}


/********** footer ************/
footer {
    background: #273049;
    color: #fff;
    width: 100%;
    height: 240px;
    display: flex;
    align-items: center;
    z-index: 0;
    font-family: var(--pt);
    font-size: .9em;
}

.f-logo {
    max-width: 250px;
    width: 100%;
}

.footer-social {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: .5em;
}

.soc-icon {
    background-color: rgb(196 196 196 / .2);
    width: 2.2em;
    height: 2.2em;
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: var(--transition);
}

.soc-icon:hover {
    background-color: rgb(var(--color-2) / 1);
}

.footer-nav ul {
    list-style: none;
    padding-left: 0;
    column-count: 2;
    margin-bottom: 0px;
}

.footer-nav ul li:not(:last-child) {
    margin-bottom: 1em;
}

.footer-nav ul li a {
    font-size: 1em;
    text-transform: uppercase;
}

.footer-nav ul li a:hover {
    color: rgb(var(--color-2));
    transition: var(--transition);
}

.mailing-form input[type="text"] {
    flex: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: rgb(169 169 169 / .2);
    border: none;
}

.mailing-form button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@media (max-width: 991px) {
    .footer-nav ul {
        column-count: 1;
    }

    footer {
        height: 270px;
    }
}

@media (max-width: 767px) {
    footer {
        background-color: #2E5193;
        height: 48px;
        color: white;
        position: sticky;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 100;
        box-shadow: 0px 0px 20px rgb(60 100 177 / 15%);
        font-size: 10px;
    }

    footer nav a {
        text-align: center;
        position: relative;
    }

    footer nav a img {
        display: block;
        margin: 0 auto;
    }

    footer nav a .count {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgb(var(--color-2));
        color: #fff;
        font-size: 8px;
        width: 13px;
        height: 13px;
        line-height: 13px;
        text-align: center;
        border-radius: 50%;
    }
}

/* NotFound page*/
.not-found {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 3rem;
}

.not-found__title {
    text-align: center;
    margin: 0;
}

.not-found__link {
    text-align: center;
    padding: 0;
}

/* Loader component */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
#imgForVideo{
    cursor:pointer;
    position: relative;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

/* .modal__photosAdd {

} */

.mainModalPhotos {
    padding: 2em;
}

.modal__photosAdd .modal-body {
    padding: 0;
}

.modal-body textarea {
    min-height: 100px;
}

.modal__photosAdd .modal-content {
    border: none;
}

.modal__photosAdd .modal-header {
    padding: 1em 1em 0 1em;
    background: #E2E8F3;
    border: none;
}

.modal__photosAdd .modal-header button {
    color: black;
}

.modal__report .modal-header {
    height: 45px;
    background: #E2E8F3;
}

.modal__messages .modal-header {
    height: 45px;
    background: #E2E8F3;
}

.itemsModalPhotos {
    width: auto;
    height: 20em;
    border-radius: 4px;
    margin-bottom: 1.5em;
}

.view-items {
    overflow: auto;
    padding: 1.5em;
    border: 1px solid #E7E8EC;
}

.photos-items span {
    padding-left: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
}

.buttonsModalPhotos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.buttonsModalPhotos .miniGroup {
    display: flex;
    align-items: center;
}

.buttonsModalPhotos .miniGroup span {
    margin-left: 1em;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #8D8D8D;
}

#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8fafc;
    text-align: center;
}

#label-file-upload p {
    color: #8D8D8D;
    font-size: 1.2em;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.dragAndDropInItems {
    border: 1px solid #E7E8EC;
    height: inherit;
    border-radius: 4px;
}

.photos-window {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
}

.photos-window:not(:first-child) {
    padding-top: 1em;
}

.photos-items {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
}

.photos-items img {
    width: 3em;
    height: 3em;
}

.photos-items-preview img {
    width: 10em;
    height: 10em;
    border: thin solid lightgray;
    border-radius: 8px;
    object-fit: cover;
}

.photos-window button {
    color: #3670B3;
}

.photos-window.preview {
    position: relative;
}

.photos-window.preview button {
    position: absolute;
    top: 1.5em;
    right: 0.5em;
    color: gray;
}

.photos-window.preview button div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
}

/* Form-validate */
.validate-wrapper {
    position: relative;
    width: 100%;
}

.validate-wrapper_error>input {
    border-color: red;
}

.validate-wrapper_error>textarea {
    border-color: red;
}

.validate-error {
    position: absolute;
    left: 20px;
    bottom: -7px;
    background-color: #FFF;
    font-size: 14px;
    color: red;
    padding: 0 5px;
}

.photo-upload__desc>span {
    color: red;
}

.for-ad-blocks {
    object-fit: contain;
}

.for-photos-dragAndDrop {
    object-fit: contain;
}

.confirm_email {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
}

.email_input {
    width: 100%;
}

.acc-video-block>a {
    color: #0c63e4;
}

.anchor {
    overflow-anchor: auto;
    height: 1px;
}

.gray-text {
    font-size: 0.8em;
    color: gray;
}

.inputCity {
    border: none;
    margin-top: 2px;
    margin-left: 2px;
    height: 90%;
    width: 90%;
    z-index: 9;
    position: absolute;
}

.citiesSearch {
    cursor: pointer;
    max-height: 160px;
    width: 100%;
    position: absolute;
    z-index: 9;
    background-color: red;
    overflow-y: scroll;
    box-shadow: 4px 0 #EFEFEF;
}

.citiesSearch::-webkit-scrollbar {
    background-color: #dedede;
}

.citiesSearch::-webkit-scrollbar-thumb {
    background-color: silver;
}

.privacy p {
    padding: 10px 0 0 20px;
}

.privacy .paragraph {
    font-size: 1.1em;
}

.privacy .paragraph a {
    color: deepskyblue;
}

.img-advertising {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.blockAdvertising {
    width: 100%
}

@media (min-width: 1200px) {
    .blockAdvertising {
        height: 353px;
    }
}

@media (max-width: 1199px) {
    .blockAdvertising {
        height: 323px;
    }

}

@media (max-width: 991px) {
    .blockAdvertising {
        height: 270px;
    }

}

@media (max-width: 767px) {
    .blockAdvertising {
        height: 221px;
    }
}

@media (max-width: 575px) {
    .blockAdvertising {
        height: 202px;
    }
}
.badAdv{
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: block;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
.validate-error-textarea {
    top: auto;
    bottom: -8px;
}

.validate-wrapper_error>.RichEditor-root{
    border-color: red;
}
input::placeholder {
    font-weight: 400;
    opacity: 0.6;
    color: black;
}